import React from "react";
import CardGroup from 'react-bootstrap/CardGroup';
import CardsHomePage2 from "../components/CardsHomePage2";
import Typography from '@mui/material/Typography';

import logo_babies from '../images/logo_babies.jpg';


function Babies() {
        return (
                <div className="App-header" >
                        <div class="container">

                                <Typography variant="h4" >Ali-Express Essentials for Moms & Newborns - on Telegram</Typography>

                                <CardGroup class="text-dark card-group" style={{ justifyContent:"center" }}>

                                        <CardsHomePage2
                                                headerText="Newborn Essentials"
                                                mainLogo={logo_babies}
                                                cardText="Shop cute baby clothes, cozy swaddles, games, and breastfeeding accessories — all at great prices. Don’t miss out"
                                                url="https://t.me/upgradeMyBaby"
                                        />

                                </CardGroup>


                                <div class="container" style={{ marginTop: 40 }}>
                                        <a
                                                className="App-link"
                                                href="https://s.click.aliexpress.com/e/_AAdgIV"
                                                target="_blank" // open in a new tab
                                                rel="noopener noreferrer"
                                        >
                                                <Typography variant="h4">Ali Express</Typography>

                                        </a>
                                </div>
                        </div>
                </div>
        );
}

export default Babies;
