import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavigationBar from "./components/NavigationBar";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Hebrew from './pages/Hebrew'
import Arabic from './pages/Arabic';
import BabiesLandingPage from './pages/Babies';


// firebase
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDAtP7LtwjNawASn52OFvKWlwAtXwHkcy0",
  authDomain: "upgraders-israel.firebaseapp.com",
  projectId: "upgraders-israel",
  storageBucket: "upgraders-israel.appspot.com",
  messagingSenderId: "999473776505",
  appId: "1:999473776505:web:7f8657796fd096340a91a8",
  measurementId: "G-X946537FJN"
};
// const app = initializeApp(firebaseConfig);
initializeApp(firebaseConfig);


function App() {

  return (
    <div className="App" dir="rtl" >
      <div className="font-face-heebo">

        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Home />} /> {/* instead of a 404 page */}
            <Route path="/hebrew" element={<Hebrew />} />
            <Route path="/babies" element={<BabiesLandingPage />} />

          </Routes>
        </BrowserRouter>





        {/* <NavigationBar /> */}

      </div>
    </div>

  );
}

export default App;
