import React from "react";
import Typography from '@mui/material/Typography';
import logo_upgraders from '../images/logo_upgraders.png';


function Home() {
        return (
                <div className="App-header" >
                        <div class="container">

                                <Typography variant="h4" >Welcome Upgraders</Typography>

                                <div class="container" style={{ marginTop: 40 }}>
                                        <a
                                                className="App-link"
                                                href="https://s.click.aliexpress.com/e/_AAdgIV"
                                                target="_blank" // open in a new tab
                                                rel="noopener noreferrer"
                                        >
                                                <Typography variant="h4">Ali-Express</Typography>

                                        </a>
                                </div>


                        </div>
                </div>
        );
}

export default Home;
