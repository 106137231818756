import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';


function ActionAreaCard(props) {
  return (
    <center>
      <Card sx={{ maxWidth: 250 }} style={{ marginRight: "30px", marginLeft: "30px", marginTop: "20px", marginBottom: "20px" }} >

      <CardActionArea href={props.url} target="_blank" >
        <CardMedia
          component="img"
          height="200"
          image={props.mainLogo}
          alt={props.imageAlt}
        />
        </CardActionArea>


        <CardContent >
          <CardActionArea href={props.url} target="_blank" >
            <Typography gutterBottom variant="h5" sx={{ color: 'text.primary', p: 1, textDecoration: 'underline' }} ><b>{props.headerText}</b></Typography>
          </CardActionArea>
          <Typography variant="h6" color="text.primary">{props.cardText}</Typography>
        </CardContent>

      </Card>
    </center>
  );
}

export default ActionAreaCard;